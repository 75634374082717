<template>
  <div class="thank-you-message">
    <div v-html="thankyouMessageTitle"></div>

    <div class="divider"></div>

    <div v-html="thankyouMessageText"></div>

    <custom-button variant="primary" @click="logout">
      Aceptar
    </custom-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CustomButton from '../components/CustomButton.vue';

export default {
  name: 'ThankYouMessage',

  components: { CustomButton },

  computed: {
    ...mapState({
      thankyouMessageTitle: (state) => state.survey.goodbyeMessageTitle,
      thankyouMessageText: (state) => state.survey.goodbyeMessageText,
      finishedSurvey: (state) => state.userProfile.finishedSurvey,
      activeSurveyId: (state) => state.company.activeSurveyId,
      endDate: (state) => state.survey.endDate,
    }),
  },

  methods: {
    ...mapActions(['logout']),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.activeSurveyId) return next('/no-survey');

      const endDate = new Date(`${vm.endDate}T00:00:00`.replace(/-/g, '/').replace(/T.+/, '')).toISOString().slice(0, 10);

      const today = new Date().toISOString().slice(0, 10);

      if (endDate < today) return next('/no-survey');

      if (!vm.finishedSurvey) return next('/welcome');

      return next();
    });
  },
};
</script>

<style scoped>
.thank-you-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  width: 20px;
  height: 2px;
  margin-bottom: var(--lengthMd1);
  background-color: var(--mainColor1);
}

.btn {
  margin: var(--lengthLg1) auto 0;
}

.logo {
  margin: 0 auto var(--lengthLg1);
}
</style>
